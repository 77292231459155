const newsData = [
    {
        title: "Duluth Regional Competition 2024",
        desc: "Read about our yearly Duluth regional!",
        article: "And that’s a wrap on the 2024 FRC Northern Lights Regional! We had a blast meeting and competing with other teams! It was an electric experience and we are all very proud of what we were able to display. Not only did we put forth a competitive performance, but we also had fun. As a result, our team's bond became stronger because of all the great memories we created. Thank you so much to our mentors, FRC volunteers and staff, and other teams for making this happen! Now we are charged up for the upcoming State Tournament!!",
        date: "March 4, 2024",
        srcOfImage: "/assets/images/duluth2024.jpg",
        author: "Lakshmi Kandikonda"
    },
    {
        title: "Trojan Robotics at the World Championship",
        desc: "Read about our competition in Houston!",
        article: "Following our success at the Iowa Regionals, our team packed our bags and headed to Houston, Texas, for the highly anticipated FRC Worlds Championship! This marked the pinnacle of our robotics season, giving us a unique opportunity to engage with diverse teams from around the globe and make meaningful connections, expanding our network of friends within the robotics community.",
        date: "April 30, 2023",
        srcOfImage: "/assets/images/teamHoustonPic.jpg",
        author: "Lakshmi Kandikonda"
    },
    {
        title: "Iowa State Competition 2023",
        desc: "We qualified for the world championship in Houston!",
        article: "In 2023, our team attended the FRC Iowa Regionals for the first time. With this being our second regional of the year, we had gotten our bearings and were ready. Remarkably, we secured first place and the role of alliance captain, a triumph that took us to the World Championships in Houston. ",
        date: "March 23, 2023",
        srcOfImage: "/assets/images/trojanRoboticsIowa2023.jpg",
        author: "Lakshmi Kandikonda"

    },

]

export default newsData;