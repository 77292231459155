const sponsorsDict = [
    {
        name: "3M",
        company_link: "https://www.3m.com/",
        logoSource: "https://news.3m.com/image/Trifecta+3M+001+Logo+CMYK_thumb.jpg",
    },
    {
        name: "Philips",
        company_link: "https://www.philips.com/global",
        logoSource: "https://seeklogo.com/images/P/philips-logo-958ECE926C-seeklogo.com.png",
    },
    {
        name: "Gene Haas Foundation",
        company_link: "https://ghaasfoundation.org/content/ghf/en/home.html",
        logoSource: "https://www.haascnc.com/content/dam/haascnc/gene-haas-foundation/2021-logo-ghf/GHF_Logo_LightBackground.jpg/jcr:content/renditions/original.original/image.jpg",
    },
    {
        name: "Cargill",
        company_link: "https://www.cargill.com/",
        logoSource: "https://www.cargill.com/image/1432080092113/cargill-color-logo.jpg",
    },
    {
        name: "Wayzata High School",
        company_link: "https://www.wayzataschools.org/whs",
        logoSource: "https://www.mshsl.org/sites/default/files/styles/large/public/logos/2021-09/trojan_head_blue_gold.png?itok=duFUwVHz",
    },
    {
        name: "Medtronic",
        company_link: "https://www.medtronic.com/us-en/index.html",
        logoSource: "https://1000logos.net/wp-content/uploads/2021/09/Medtronic-Logo.png",
    },
    {
        name: "United Healthcare",
        company_link: "https://www.uhc.com/",
        logoSource: "https://1000logos.net/wp-content/uploads/2018/02/United-Healthcare-Logo-800x429.png",
    },
    {
        name: "FIRST",
        company_link: "https://www.firstinspires.org/",
        logoSource: "https://www.firstinspires.org/sites/default/files/open-graph-first-logo.png",
    },
    {
        name: "Novo Engineering",
        company_link: "https://novoengineering.com/",
        logoSource: "https://www.dexigner.com/images/directory/xi/29728.jpg",
    },
    {
        name: "Cambria",
        company_link: "https://www.cambriausa.com/",
        logoSource: "https://www.qualitygranitenh.com/wp-content/uploads/2017/03/cambria-logo.png",
    },
    {
        name: "Friends and Family",
        logoSource: "https://www.liveremotesupport.net/content/wp-content/uploads/2019/03/friends-family.jpg",
    },
    
]

export default sponsorsDict;